import React, { } from 'react';
import './App.scss';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
function PosterContest() {
    return (

        <div className='innerPageSec contentArea inter-ship-sec'>
        <section id='home' className='contestpage poster'>
            <div className='bannerBgView newHeight'>
            <Container>
                <div className="bannerTwo">
                <div>
                    <h2>Contest Announcement</h2>
                    <p>Poster Designing Contest</p>
                    <em>Prizes worth <strong>Rs 1 Lac !!</strong></em>
                    <div className="ctaBtn"><Link to={"https://docs.google.com/forms/d/e/1FAIpQLSe7p8pGyMPcgLN2y0xqFAAadSbN3O0RpHAvnsEY6ugzI3jhNw/viewform?usp=sf_link"} target='_blank'>Submit Now</Link></div>
                </div>
                <div className='bgPoster'>
                    <span>
                    <img src='/images/posterBanner.svg' alt='Break The Silence' />
                    {/* <a href="javascript:void(0);"><img src='/images/vdoBtn.svg' alt='Play Video' /></a> */}
                    </span>
                </div>
                </div>
            </Container>
            </div>
        </section>
        <section className="excitedAnnounce">
            <Container>
            <div className="excitedAnnounceBanner">
                <b>We are excited to announce ABPUA poster designing competition to raise awareness about
                the critical issue of student suicides in Kota.</b>
                <div>
                <span className="breakSilence"><i>Theme</i><strong>Breaking the Silence :</strong> Addressing Student Suicides in Kota</span>
                </div>
            </div>
            <div className="objectiveBlocks">
                <h3>Objective</h3>
                <span>This competition aims to:</span>
                <div className="blocksThree">
                    <div>Spark dialogue and understanding about the factors contributing to student suicides in Kota.</div>
                    <div>Encourage creative solutions and inspire positive change.</div>
                    <div>Break the silence surrounding this critical issue.</div>
                </div>
            </div>
            <div className="eligibility">
                <h3>Eligibility</h3>
                <span>All NID students are welcome to participate, either individually or in teams.</span>
            </div>
            </Container>
        </section>

        <section className="prizesBg">
            <Container>
            <div className="prizesTitle">
                <div className='titleSet'>
                    <h3>Prizes</h3> <img src="/images/prizeAward.svg" alt="Prize" />
                </div>
            </div>
            <div className="prizeList">
                <div>
                    <em>1</em>
                    <b>₹ 50,000</b>
                    <p>First Prize</p>
                </div>
                <div>
                    <em>2</em>
                    <b>₹ 25,000</b>
                    <p>Second Prize</p>
                </div>
                <div>
                    <em>3</em>
                    <b>₹ 15,000</b>
                    <p>Third Prize</p>
                </div>
                <div>
                    <em>Each</em>
                    <b>₹ 5,000</b>
                    <p>2 Consolation Prizes</p>
                </div>
            </div>
            </Container>
        </section>
        <section className="guidelines">
            <Container>
                <h3>Guidelines</h3>
                <div className="guideBlocks">
                <div>
                        <img src="/images/contentIcon.svg" alt="" />
                        <span>
                        <b>Content</b>
                        <p>Posters must focus on the issue of student suicides in Kota</p>
                        </span>
                    </div>
                    <div>
                        <img src="/images/sizeIcon.svg" alt="" />
                        <span>
                        <b>Size</b>
                        <p>Posters should be submitted in A3, A4 and A5 sizes (all 3 sizes)</p>
                        </span>
                    </div>
                    <div>
                        <img src="/images/formatIcon.svg" alt="" />
                        <span>
                        <b>Format</b>
                        <p>Submit your entries in vector format</p>
                        </span>
                    </div>
                    <div>
                        <img src="/images/deadlineIcon.svg" alt="" />
                        <span>
                            <b>Deadline</b>
                            <p>Submissions are due by <i>11:59:59 PM</i> on <i>September 7, 2024</i>.</p>
                        </span>
                    </div>
                </div>
            </Container>
        </section>

        <section className="judgingCriteria">
            <Container>
            <h3>Judging Criteria</h3>
                <div className="judging">
                    <div className="firstTwo">
                        <div>
                            <img src="/images/createOriginalIcon.svg" alt="Creativity and Originality" />
                            <span>
                            <b>Creativity and Originality</b>
                            <p>We are looking for fresh perspectives and innovative approaches to the theme.</p>
                            </span>
                        </div>
                        <div>
                            <img src="/images/impactIcon.svg" alt="Impact and Message" />
                            <span>
                            <b>Impact and Message</b>
                            <p>Does your work effectively raise awareness and inspire positive action?</p>
                            </span>
                        </div>
                    </div>
                    <div className="firstTwo borderShow">
                        <div>
                            <img src="/images/qualityIcon.svg" alt="Technical Quality" />
                            <span>
                            <b>Technical Quality</b>
                            <p>Ensure high resolution of the posters</p>
                            </span>
                        </div>
                        <div>
                            <img src="/images/impactIcon.svg" alt="Impact and Message" />
                            <span>
                            <b>Adherence to the Theme</b>
                            <p>Your work should directly address the issue of student suicides in Kota.</p>
                            </span>
                        </div>
                    </div>
                </div>
            </Container>
        </section>

        <section className="submitNow">
            <Container>
                <div className="submitBlock">
                    <i><img src="/images/star-icon.svg" alt="Star" /></i>
                    <em><img src="/images/pie-icon.svg" alt="Icon" /></em>
                    <b>Submit Your Poster Now!</b>
                    <p>We are looking forward to receive your submissions.</p>
                    <Link to={"https://docs.google.com/forms/d/e/1FAIpQLSe7p8pGyMPcgLN2y0xqFAAadSbN3O0RpHAvnsEY6ugzI3jhNw/viewform?usp=sf_link"}target='_blank'>Submit Now <img src="/images/btn-arrow-icon.svg" alt="Submit Your Entry" /></Link>
                </div>
            </Container>
        </section>
            
        <section className="storyTelling">
            <Container>
                <div className="storyBlocks">
                <h4>Use the power of storytelling to break the silence and inspire change. </h4>
                <p>Together, we can create a brighter future for students.</p>
                </div>
            </Container>
        </section>
        </div>
    )
}

export default PosterContest;