import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './shared/header/header';
import Footer from './shared/footer/footer';
import Postercontest from './postercontest';
import Homepage from './homepage';
import Videocontest from './videocontest';

function App() {
  return (
    <div className="App">
       <Router>
        <Header/>
        <Routes>
        <Route path="" element={<Homepage/>} />
        <Route path="/postercontest" element={<Postercontest/>} />
        <Route path="/videocontest" element={<Videocontest/>} />
        <Route path="" element={<Homepage/>} />
        <Route path="*" element={<Navigate to="" />} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
